<template>
  <SfSidebar
    :visible="isOpen"
    class="m-sidebar-mobile-filters mobile-only"
    @close="close"
  >
    <ALoadingSpinner
        v-show="productsIsLoading"
        :size="24"
        :weight="3"
        :is-absolute-position="true"
    />
    <template #bar>
      <SfBar
        class="sf-bar--filters"
      >
        <template #title>
          <div class="sf-bar__title m-sidebar-mobile-filters__title">
            {{ $t('Filters') }}
          </div>
        </template>
        <template #close>
          <div class="sf-bar__buttons">
            <SfButton
              v-if="filtersCount"
              class="sf-button--pure sf-bar__clear"
              @click="clear"
            >
              {{ $t("Clear all") }}
            </SfButton>
            <SfButton
              class="sf-button--pure sf-button--close"
              @click="close"
            >
              <SfIcon
                icon="cross"
                size="xxs"
                color="secondary"
              />
            </SfButton>
          </div>
        </template>
      </SfBar>
    </template>
    <div class="filters">
      <div
        v-if="!searchPage"
        class="m-sidebar-mobile-filters__item m-sidebar-mobile-filters__item--system"
      >
        <MCategoryFilterRegional
          @stock-filter-changed="availableTodayFilterChanged"
          @close-filter="close"
        />
        <MCategoryFilterPromo
          @promo-filter-changed="promoFilterChanged"
          :promo-count="promoCount"
        />
        <MCategoryFilterMarkdown
          v-if="isMarkdownFilterActive"
          :markdown-count="markdownCount"
          @markdown-filter-changed="markdownFilterChanged"
        />
      </div>
      <div class="m-sidebar-mobile-filters__item">
        <SfButton
          class="sf-button--text m-sidebar-mobile-filters__button"
          @click="openSidebar('sort')"
        >
          {{ $t('Sort By') }}
          <span>{{ $t(sortLabel) }}</span>
        </SfButton>
      </div>
      <div
        v-if="availableFilters.hasOwnProperty('price')"
        class="m-sidebar-mobile-filters__item"
      >
        <SfButton
          class="sf-button--text m-sidebar-mobile-filters__button"
          @click="openSidebar(availableFilters.price.type)"
        >
          {{ $t('Price') }}, грн
          <div class="sf-button--subtitle" v-if="Object.keys(selectedPrice).length > 0">
            <span>{{ selectedPrice[0] }} - {{ selectedPrice[1] }}</span>
          </div>
        </SfButton>
      </div>
      <MVaruscafeMobilFilter
        v-if="isVaruscafePage"
        :available-filters="availableFilters"
        :current-filter="currentFilter"
        :open-sidebar="openSidebar"
      />
      <template
        v-for="(filters, filterType) of availableFilters"
        v-if="excludeFilters(filterType) && !disablePimBrandId(filterType)"
      >
        <div
          :key="filterType"
          class="m-sidebar-mobile-filters__item"
        >
          <SfButton
            class="sf-button--text m-sidebar-mobile-filters__button"
            @click="openSidebar(filterType)"
          >
            {{ filterLabels[filterType] || filterType }}
            <div class="sf-button--subtitle">
              <span v-for="(filter, index) of currentFilter(filters, filterType)" :key="index">{{ filter.label }}<span class="coma">,</span> </span>
            </div>
          </SfButton>
        </div>
      </template>
    </div>
    <template #content-bottom>
      <div class="filters__buttons">
        <SfButton
          class="sf-button--primary sf-button--full-width"
          @click="close"
        >
          {{ $t("Done") }}
          <span
            v-if="filtersCount"
            class="filters__buttons--count"
          >
            ({{ filtersCount }})
          </span>
        </SfButton>
      </div>
    </template>
  </SfSidebar>
</template>
<script>
import {
  SfSidebar,
  SfBar,
  SfButton,
  SfIcon
} from '@storefront-ui/vue';
import MCategoryFilterPromo from './m-category-filter-promo';
import MVaruscafeMobilFilter from 'theme/components/molecules/m-varuscafe-mobile-filter'
import ALoadingSpinner from 'theme/components/atoms/a-loading-spinner.vue';
import MCategoryFilterMarkdown from 'theme/components/molecules/m-category-filter-markdown.vue';
import { markdown } from '../../../../../docs/.vuepress/config';
import MCategoryFilterRegional from 'theme/components/molecules/m-category-filter-regional.vue';

export default {
  name: 'MSidebarMobileFilters',
  components: {
    MCategoryFilterRegional,
    MCategoryFilterMarkdown,
    ALoadingSpinner,
    MCategoryFilterPromo,
    SfSidebar,
    SfBar,
    SfButton,
    SfIcon,
    MVaruscafeMobilFilter
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    availableFilters: {
      type: Object,
      default: () => ({})
    },
    promoCount: {
      type: Number,
      default: 0
    },
    markdownCount: {
      type: Number,
      default: 0
    },
    sortLabel: {
      type: String,
      default: ''
    },
    filterLabels: {
      type: Object,
      default: () => ({})
    },
    filtersCount: {
      type: Number,
      default: null
    },
    filterActived: {
      type: Object,
      default: () => ({})
    },
    searchPage: {
      type: Boolean,
      default: false
    },
    isVarusCafePage: {
      type: Boolean,
      default: false
    },
    hideOnBrandPage: {
      type: Boolean,
      default: false
    },
    productsIsLoading: {
      type: Boolean,
      default: false
    },
    isMarkdownFilterActive: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    markdown () {
      return markdown
    },
    selectedPrice () {
      let priceSelected = []
      if (this.searchPage) {
        if (this.$route.query.price !== undefined) {
          if (Array.isArray(this.$route.query.price)) {
            priceSelected = this.$route.query.price[0].split('-')
          } else {
            priceSelected = this.$route.query.price.split('-')
          }
        }
      } else {
        if (this.$route.params.price !== undefined) {
          if (Array.isArray(this.$route.params.price)) {
            priceSelected = this.$route.params.price[0].split('-')
          } else {
            priceSelected = this.$route.params.price.split('-')
          }
        }
      }

      return priceSelected
    },
    isVaruscafePage () {
      return this.$route.name.includes('varuscafe')
    }
  },
  methods: {
    openSidebar (filterName) {
      this.$emit('open', filterName)
    },
    promoFilterChanged (filter) {
      this.$emit('promo-filter-changed', filter)
    },
    availableTodayFilterChanged (filter) {
      this.$emit('for-np-filter-changed', filter)
    },
    markdownFilterChanged (filter) {
      this.$emit('markdown-filter-changed', filter)
    },
    clear () {
      this.$store.dispatch('category-extension/filterCategoryPrice', {});
      this.$emit('clear')
    },
    close () {
      this.$emit('close')
    },
    excludeFilters (filterType) {
      return filterType !== 'has_promotion_in_stores' &&
      filterType !== 'price' &&
      filterType !== 'varus_cafe'
    },
    currentFilter (filters, filterType) {
      if (Array.isArray(filters)) {
        let filterActived = this.filtersCount !== 0 ? this.filterActived : {}
        let current = filterActived[filterType]
        return current !== undefined && current.length > 0 ? current : ''
      }
    },
    disablePimBrandId (pimBrandid) {
      return (this.hideOnBrandPage || this.isVarusCafePage) && pimBrandid === 'pim_brand_id'
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/mixins";

.m-sidebar-mobile-filters {
  ::v-deep {
    .sf-bar {
      --bar-height: 3.75rem;
      border-bottom: 2px solid var(--light-gray);

      &--filters {
        justify-content: flex-start;
        padding: var(--spacer-xs) var(--spacer-base);

        & > div {
          &:last-child {
            margin-left: auto;
          }
        }
      }

      &__title {
        font-size: 1.25rem;
        line-height: var(--spacer-base);
      }

      &__buttons {
        display: flex;
      }
    }

    .sf-checkbox__label {
      font-size: var(--font-size-13);
    }
    .o-filter-stock, .mcf-regional {
      margin: 0 0 20px;
    }
  }

  .sf-bar__clear {
    font-size: var(--font-sm);
    color: var(--orange);
    font-weight: normal;
  }

  .sf-button--close {
    margin-left: 30px;
  }

  ::v-deep .m-varuscafe-mobile-filter > div,
  &__item {
    display: block;
    border-bottom: 1px solid var(--line-gray);
    padding: 0 0 var(--spacer-base) 0;
    margin-bottom: var(--spacer-base);
  }

  ::v-deep .m-varuscafe-mobile-filter .m-sidebar-mobile-filters__button,
  &__button {
    font-size: var(--font-base);
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    width: 100%;

    &:after {
      content: '';
      width: .3rem;
      height: .3rem;
      border-top: 2px solid var(--black);
      border-left: 2px solid var(--black);
      transform: rotate(135deg);
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;

      @include for-desktop {
        content: none;
      }
    }

    span {
      font-size: var(--font-size-13);
      color: var(--dark-gray);
      padding-top: var(--spacer-xs);
      font-weight: normal;
    }

    &:hover {
      --button-color: none;
    }
  }

  ::v-deep .m-varuscafe-mobile-filter .sf-button--subtitle,
  .sf-button--subtitle {
    display: flex;
    flex-wrap: wrap;
    line-height: var(--spacer-8);

    .coma {
      margin-right: var(--spacer-5);
    }

    & > span {
      &:last-of-type {
        .coma {
          display: none;
        }
      }
    }

  }

  &__title {
    @include header-title(22, 22);
  }
}

::v-deep {
  .sf-sidebar__content {
    padding: var(--spacer-20);
    .sf-button {
      &:active {
        --button-background: none;
        --button-color: var(--c-text);
      }
    }
  }
}

::v-deep {
  .sf-bar__icon {
    display: none;
  }
}

.filters__buttons--count {
  margin-left: var(--spacer-10);
}
</style>
